/* Post.css */
.main-class
{
  /* border: solid; */
  display: flex;
  justify-content: center;
}
.post-container {
  padding: 20px;
    margin-top: 50px;
    /* border: solid; */
    width: 70%;
   
  }
  
  .post-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .post-content {
    line-height: 1.6;
    font-size: 1.1rem;
  }
  @media only screen and (max-width: 768px) {
    .post-container {
      padding: 20px;
        margin-top: 20px;
        /* border: solid; */
        width: 100%;
       
      }
  }
  /* Add more styling as needed */
  