.contact-main{
    /* background-image: url(/src/image/servicesbg.svg); */
    background-color: black;
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   
}

.bg-image {
  background-color: #bbcf5f;
}
.contact{
  font-size: 50px;
  text-align: end;
  margin-right: 5%;
  margin-bottom: 10%;
  font-weight: 700;
}
@media screen and (max-width:768px){
  .contact{
    text-align: start;
  }
}
@media screen and (max-width:490px){
  .contact{
    text-align: center;
    font-size: 30px;
  }
  .cont-head h2{
    text-align: center;
  }
  .cont-head p{
    text-align: center;
  }
}
.cont-head h2{
  font-size: 30px;
}
.main-card{
  box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1),
  0 32px 64px -48px rgba(0, 0, 0, 0.5);
border-radius: 5px;
}
.contact-btn{
  color: rgb(80, 89, 25);
    font-weight: 500;
    font-size: 15px;
    width: 156px;
    height: 50px;
    border-color: rgb(80, 89, 25);
    border-radius: 5px;
    background-color: #fff;
    transition: 0.5s;
    border: 2px solid;
}
.contact-btn:hover{
  background-color: rgb(80, 89, 25);
  color: #fff;
}
.form p{
  color: red;
  padding: 2px;
}
.cont-img img{
  margin-top: -60px;
  width: 400px;
}
.btn-sub{
  background: linear-gradient(90deg,#ff4d7e 0,#ff6a5b);
  padding: 11px 20px;
  border-radius: 6px;
}
.detail-box h2{
  font-size: 20px;
}
@media screen and (max-width : 480px){
  .cont-img img{
    width: 200px;

  }
}