
.main-div {
  box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1),
    0 32px 64px -48px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.div-header p {
  font-size: 18px;
  color: #8a94a5;
}
.div-header h3 {
  font-size: 30px;
  color: #15284b;
}
.btns1 {
  color: blueviolet;
  font-weight: 500;
  font-size: 15px;
  width: 235px;
  height: 50px;
  border-color: blueviolet;
  border-radius: 30px;
  background-color: #fff;
  transition: 0.5s;
  border: 2px solid;
}

.btns1:hover {
  background-color: blueviolet;
  color: #fff;
}

.btns2 {
  color: white;
  font-size: 15px;
  width: auto;
  text-wrap: nowrap;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  
  background-color: blueviolet;
  transition: 0.5s;
  /* border: 2px solid; */
  display: flex;
}

.btns2:hover {
  background-color: rgb(157, 86, 223);
  color: #ffffff;
  font-weight: 500;
  scale: 1.1;
}
