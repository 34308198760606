*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
}
.main-nav{
	padding: 10px;
}

.nav-item {
	padding: 10px;
	font-size: 15px;
	color: white;
	
}
.nav-item:hover {
	color: rgb(80, 89, 25);
	border-radius: 5px;
	font-weight: 600;
}
.nav-item:after {
	content: '';
	width: 0%;
	height: 2px;
	background: rgb(80, 89, 25) !important;
	color: rgb(80, 89, 25) !important;
	display: block;
	margin: auto;
	transition: .5s;
}

.nav-item:hover:after {
	width: 100%;
	list-style-type: none;


}
@media screen and (max-width: 991px) {
	.nav-item:hover {
	  background-color: rgb(80, 89, 25);
	  border-bottom: none;
	  color:white;
	}
	.nav1 {
		margin-top: 20px;
	}
	
	.btn-nav {
		border-radius: 0;
		width: 110px;
		align-self: center;
		position: relative;
		top: 5px;
	}
	
  }
  .logop {
	color: white;
	font-weight: 400;
  }
  .logop:hover{
	font-weight: 500;
	color:rgb(80, 89, 25);
  }
.logo img {
	 width: 65px;
	 height: 65px;
	/* color: rgb(80, 89, 25); */
}
.logo img:hover{
	color: rgb(133, 150, 21);
}
.btn-nav {
	background-color:rgb(80, 89, 25) ;
	color: white;
	
	border-radius: 3px;
}
.btn-nav:hover{
	color: white;
	background-color: rgb(133, 150, 21) ;
	font-weight: 500;

}