/* .animation img {
  position: relative;
  height: 100%;
  width: calc(130% - 80px);
  top: 50px;
  right: 20px;
  bottom: 10px;

  animation: animateUser 5s linear infinite;
} */


.maintext {
  font-weight: bold; 
}
@media screen and (max-width:480px){

  .para1 {
    text-align: center;
  }
  .maintext{
    font-weight: 700;
  }
  
}

@media (max-width: 1024px) {
  /* Styles for screens with a width up to 1024px */
  .tech-log{
    margin-top: 50px;
  }
}


@keyframes animateUser {
  50% {
  
    top: 10px;
  }
}
.bg-sam {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 50px;
}
.text-textColor {
  color:rgb(80, 89, 25);
}
.home-main {
  padding-top: 100px;
}

.logo {
  width: 14rem;
}
.homeimg {
  background-image: url(/src/image/logo-trans.jpeg);
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  vertical-align: middle;
  padding-bottom: 50px;
  /* background-color: rgb(100,149,237); */
  /* background-color: black */

}

.backgimg {
  /* background-image: url(/src/image/img2.webp);
   */
   background-color: black;
  /* width: 100%; */
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  vertical-align: middle;
}
.soft-img img {
  width: 80%;

}

.my-img {
  border: 30px solid white;
  box-shadow: 0 15px 32px rgb(122 130 171 / 20%);
  border-radius: 8px;
}


.header-img .img3 {
  width: 450px;
  margin-top: -300px;
  position: relative;
}

.header-img .img8 {
  border: none;
  width: 500px;
  margin-left: -20px;
  z-index: -1;
  margin-top: 40px;
  margin-bottom: 60px;
}

.header-img .img8 img {
  border: none;
  
}

.header-img .img4 {
  border: none;
  width: 500px;
  margin-left: -10px;
  z-index: 1;
  position: absolute;
  margin-top: -241px;
}

.header-img .img6 {
  width: 450px;
  margin-top: -480px;
  position: relative;
}

.header-img .img9 {
  border: none;
  width: 500px;
  margin-left: -20px;
  margin-top: 320px;
}

.header-img .img9 img {
  border: none;
}

.header-img .img10 {
  border: none;
  position: absolute;
  width: 500px;
  margin-left: -20px;
  margin-top: -545px;
}

.header-img .img10 img {
  border: none;
}

.header-img .img11 {
  position: absolute;
  width: 9rem;
  height: 9rem;
  background-image: url(/src/image/img11.webp);
  background-size: cover;
  margin-top: 10%;
  margin-left: 25%;
}

.header-img .img12 {
  position: absolute;
  width: 9rem;
  height: 9rem;
  /* background-image: url(/src/image/img11.webp); */
  background-size: cover;
  margin-top: 32%;
  margin-left: -4%;
}

.min-head h1 {
  font-weight: 700;
  font-size: 50px;
}

.min-head h3 {
  font-size: 30px;
  font-weight: 400;
}

.btn-con1 {
  padding: 16px 33px;
  background-color: blueviolet !important;
  border-radius:5px !important;
  color: #fff !important;
  transition: 0.3s;
  display: block;
  font-size: 15px;
  padding: 20px !important;
  font-weight:600;
  border: 2px solid blueviolet;
  transition: 0.5s;
}
.btn-con1:hover{
  background-color: blueviolet !important;
  font-weight: 500;
}
.mt-3 button {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 320px) and (max-width: 499px) {
  .head-btn {
    display: flex;
    justify-content: center;
  }

  .btn-con1 {
    padding: 16px 33px;
    background-color: #8c40d4;
    border-color: #8c40d4;
    border-radius: 10px;
    color: #fff;
    font-size: 15px;
    display: block;
    font-weight: 600;
    border: 2px solid #8c40d4;
    transition: 0.5s;
  }
}

.cmp-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 33px;
  color: blueviolet;
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
  background-color: transparent;
  border: 2px solid;
  border-radius: 155px;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  grid-gap: 5px;
  gap: 5px;
  /* margin-top: 15px; */
  text-decoration: none;
  width: auto;
  border-color: blueviolet;
}

.cmp-btn:hover {
  background-color: rgb(152, 48, 250);
  color: white;
  border: none;
  
}

.nav_bg h4 {
  color: #545c68;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.Getbtn {
  padding: 0;
  font-size: 14px;
  letter-spacing: 0.1em;
  border: none;
  text-transform: uppercase;
  background: none;
  color: blueviolet;
  text-decoration: none;
  font-weight: 700;
}

.Develop {
  margin-bottom: 16px;
  text-align: left;
  color: rgb(80, 89, 25);
  font-weight: 800;
  font-size: 40px;
}

.Over {
  color: #313d52;
  max-width: 850px;
  font-size: 18px;
  line-height: 28px;
}

.Every {
  color: #8a94a5;
  font-size: 16px;
}

.CTO {
  color: #8a94a5;
  font-size: 14px;
  font-weight: 900;
}

.here {
  margin-top: 10%;
  font-size: 40px;
  opacity: 0.6;
}
.take {
  font-size: 30px;
}
.petro {
  color: #8a94a5;
  margin-left: 2%;
  font-size: 25px;
}

.Plat {
  color: #313d52;
  font-size: 30px;
  font-weight: 400;
  margin-left: 2%;
}

.we {
  color: #313d52;
  max-width: 950px;
  font-size: 20px;
  margin-left: 2%;
  margin-bottom: 5%;
}

.howbtn {
  padding: 0;
  font-size: 15px;
  letter-spacing: 1px;
  border: none;
  text-transform: uppercase;
  background: none;
  margin-left: 2%;
  color: blueviolet;
  text-decoration: none;
  font-weight: 700;
  transition: 0.5s;
}

.cmp-btn2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 33px;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  background-color: blueviolet;
  border-radius: 155px;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 15px;
  width: auto;
  text-align: center;
  margin-left: 40%;
  
  
}


.cmp-btn2:hover {
  background-color: rgb(157, 79, 230);
  color: white;
  font-weight: 500;
  
}
/* @media screen and (max-width:768px) {
    .cmp-btn2{
        text-align: center;
 border: 1px solid red;
    }
} */
.header-img .img13 {
  position: relative;
  margin-left: -10%;
  border: none;
  width: 450px;
}

.header-img .img13 img {
  border: none;
}

.header-img .img14 {
  position: absolute;
  border: none;
  width: 450px;
  margin-left: 2%;
  margin-top: -8%;
}

.header-img .img14 img {
  border: none;
}

@media screen and (max-width: 320px) {
  .Plat {
    font-size: 27px;
  }
}

@media (min-width: 320px) and (max-width: 499px) {
  .header-img img {
    border: 20px solid white;
  }

  .header-img .img3 {
    width: 245px;
    margin-left: -1%;
    margin-top: 10px;
    position: relative;
  }

  .header-img .img8 {
    border: none;
    width: 550px;
    margin-left: -20px;
    z-index: -1;
    display: none;
    margin-top: 400px;
  }

  .header-img .img4 {
    border: none;
    width: 550px;
    margin-left: -520px;
    z-index: 1;
    position: absolute;
    display: none;
    margin-top: -631px;
  }

  .header-img .img6 {
    width: 250px;
    margin-left: 3%;
    margin-top: -80px;
    position: relative;
  }

  .header-img .img9 {
    border: none;
    width: 550px;
    display: none;
    margin-left: -20px;
    margin-top: 400px;
  }

  .header-img .img10 {
    border: none;
    position: absolute;
    width: 550px;
    display: none;
    margin-left: -520px;
    margin-top: -632px;
  }

  /* .header-img .img11 {
    position: absolute;
    width: 9rem;
    height: 9rem;
    background-image: url(/src/image/img11.webp);
    background-size: cover;

    margin-left: 30%;
  } */

  .abs {
    display: flex;
    flex-direction: column-reverse;
  }

  .Develop {
    margin-bottom: 16px;
    color: #15284b;
    font-weight: 800;
    font-size: 30px;
  }



  .here {
    margin-top: 25%;
    /* font-size: 17px; */
  }

  .cmp-btn2 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px 33px;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
    background-color: blueviolet;
    border: 2px solid;
    border-radius: 155px;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 15px;
    text-decoration: none;
    width: auto;
    text-align: center;
    margin-left: 11% !important;
    border-color: blueviolet;
  }

  .cmp-btn2:hover {
    background-color: rgb(153, 52, 248);
    color: white;
    border: none;
  }

  .howbtn {
    padding: 0;
    font-size: 15px;
    letter-spacing: 0.1em;
    border: none;
    text-transform: uppercase;
    background: none;
    margin-left: 2%;
    color: blueviolet;
    text-decoration: none;
    font-weight: 700;
  }

  .petro {
    color: #8a94a5;
    margin-left: 2%;
    margin-top: -30%;
  }

  .header-img .img13 {
    position: relative;
    margin-left: 2%;
    border: none;
    width: 240px;
    margin-top: -45%;
  }

  .header-img .img13 img {
    border: none;
  }

  .header-img .img14 {
    position: absolute;
    border: none;
    width: 240px;
    /* margin-left: 2%; */
    margin-top: 8%;
  }

  .header-img .img14 img {
    border: none;
  }
}

@media only screen and (min-width: 500px) and (max-width: 768px) {
  .header-img img {
    border: 20px solid rgb(255, 255, 255);
  }

  .header-img .img3 {
    width: 350px;
    margin-left: -1%;
    margin-top: 10px;
    position: relative;
  }

  .header-img .img8 {
    border: none;
    width: 550px;
    margin-left: -20px;
    z-index: -1;
    display: none;
    margin-top: 400px;
  }

  .header-img .img4 {
    border: none;
    width: 550px;
    margin-left: -520px;
    z-index: 1;
    position: absolute;
    display: none;
    margin-top: -631px;
  }

  .header-img .img6 {
    width: 350px;
    margin-left: 3%;
    margin-top: -80px;
    position: relative;
  }

  .header-img .img9 {
    border: none;
    width: 550px;
    display: none;
    margin-left: -20px;
    margin-top: 400px;
  }

  .header-img .img10 {
    border: none;
    position: absolute;
    width: 550px;
    display: none;
    margin-left: -520px;
    margin-top: -632px;
  }

  .header-img .img11 {
    position: absolute;
    width: 9rem;
    height: 9rem;
    background-image: url(/src/image/img11.webp);
    background-size: cover;
    margin-top: -5%;
    margin-left: 43%;
  }

  .abs {
    display: flex;
    flex-direction: column-reverse;
  }

  .petro {
    color: #8a94a5;
    margin-left: 2%;
    margin-top: -20%;
  }

  .here {
    margin-top: 25%;
    font-size: 22px;
  }

  .cmp-btn2 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px 33px;
    color: #ffffff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
    background-color: blueviolet;
    border: 2px solid;
    border-radius: 155px;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 15px;
    text-decoration: none;
    width: auto;
    text-align: center;
    margin-left: 30% !important;
    border-color: blueviolet;
  }

  .cmp-btn2:hover {
    background-color: blueviolet;
    color: white;
    border: none;
  }

  .Plat {
    color: #313d52;
    max-width: 950px;
    font-size: 28px;
    font-weight: 600;
    margin-left: 2%;
  }

  .we {
    color: #000000;
    font-weight: 300;
    font-size: 10px;
  }

  .bg {
    height: 1200px;
    /* margin-bottom: 5%; */
  }

  .header-img .img13 {
    position: relative;
    margin-left: 2%;
    border: none;
    width: 370px;
    margin-top: -30%;
  }

  .header-img .img13 img {
    border: none;
  }

  .header-img .img14 {
    position: absolute;
    border: none;
    width: 370px;
    /* margin-left: 2%; */
    margin-top: 8%;
  }

  .header-img .img14 img {
    border: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .abs {
    display: flex;
    flex-direction: column-reverse;
  }

  .header-img .img3 {
    width: 350px;
    margin-left: -1%;
    margin-top: 100px;
    position: relative;
  }

  .header-img .img8 {
    border: none;
    width: 550px;
    margin-left: -20px;
    z-index: -1;
    display: none;
    margin-top: 400px;
  }

  .header-img .img4 {
    border: none;
    width: 550px;
    margin-left: -520px;
    z-index: 1;
    position: absolute;
    display: none;
    margin-top: -631px;
  }

  .header-img .img6 {
    width: 350px;
    margin-left: 3%;
    margin-top: -80px;
    position: relative;
  }

  .header-img .img9 {
    border: none;
    width: 550px;
    display: none;
    margin-left: -20px;
    margin-top: 400px;
  }

  .header-img .img10 {
    border: none;
    position: absolute;
    width: 550px;
    display: none;
    margin-left: -520px;
    margin-top: -632px;
  }

  .header-img .img11 {
    position: absolute;
    width: 9rem;
    height: 9rem;
    background-image: url(/src/image/img11.webp);
    background-size: cover;
    margin-top: 17%;
    margin-left: 35%;
  }

  .header-img .img12 {
    position: absolute;
    width: 9rem;
    height: 9rem;
    background-image: url(/src/image/img11.webp);
    background-size: cover;
    margin-top: 26%;
    margin-left: -4%;
  }

  .petro {
    color: #8a94a5;
    margin-left: 2%;
    margin-top: -20%;
  }

  .bg {
    height: 1200px;
    /* margin-bottom: 5%; */
  }

  .header-img .img13 {
    position: relative;
    margin-left: 2%;
    border: none;
    width: 370px;
    margin-top: -30%;
  }

  .header-img .img13 img {
    border: none;
  }

  .header-img .img14 {
    position: absolute;
    border: none;
    width: 370px;
    /* margin-left: 2%; */
    margin-top: 8%;
  }

  .header-img .img14 img {
    border: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
  .header-img .img8 {
    border: none;
    width: 400px;
    margin-left: -2px;
    z-index: -1;
    /* display: none; */
    margin-top: 342px;
  }

  .header-img .img3 {
    width: 350px;
    margin-left: 4%;
    margin-top: -230px;
    position: relative;
  }

  .header-img .img4 {
    border: none;
    width: 400px;
    margin-left: -1px;
    z-index: 1;
    position: absolute;
    /* display: none; */
    margin-top: -203px;
  }

  .header-img .img8 img {
    border: none;
  }

  .header-img .img6 {
    width: 350px;
    margin-left: -13%;
    margin-top: -417px;
    position: relative;
  }

  .header-img .img9 {
    border: none;
    width: 400px;
    /* display: none; */
    margin-left: -60px;
    margin-top: 400px;
  }

  .header-img .img9 img {
    border: none;
  }

  .header-img .img10 {
    border: none;
    position: absolute;
    width: 400px;
    /* display: none; */
    margin-left: -60px;
    margin-top: -392px;
  }

  .header-img .img10 img {
    border: none;
  }

  .header-img .img12 {
    position: absolute;
    width: 9rem;
    height: 9rem;
    background-image: url(/src/image/img11.webp);
    background-size: cover;
    margin-top: 38%;
    margin-left: -5%;
  }

  .header-img .img13 {
    position: relative;
    margin-left: 2%;
    border: none;
    width: 300px;
    margin-top: 1%;
  }

  .header-img .img13 img {
    border: none;
  }

  .header-img .img14 {
    position: absolute;
    border: none;
    width: 300px;
    /* margin-left: 2%; */
    margin-top: 1%;
  }

  .header-img .img14 img {
    border: none;
  }
}

.header-img .img3 img {
  width: 100%;
  height: auto;
}

.header-img .img6 img {
  width: 100%;
  height: auto;
}

.card-img {
  /* background-image: url(/src/image/ba.svg); */
  background-color: rgb(187, 207, 95);
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  vertical-align: middle;
  padding-bottom: 50px;
}

.hero-dev a{
  color: rgb(80, 89, 25);
  padding-left: 4px;
}
.hero-dev p {
  font-size: 18px;
  color: black;
}
.hero-dev h4 {
  font-size: 20px;
  color: #545c68;
}
.clint-div {
  padding-top: 50px;
}
.clint-div h2 {
  /* color: #44546f; */
  font-weight: 600;
  font-size: 30px;
}

.clint-div h3 {
  /* color: #44546f; */
  color: #000000;
  letter-spacing: 0.1em;
  font-family: Lato, sans-serif;
  font-size: 20px;
}

.img-div {
  width: 170px;
}

.img-quote {
  width: 10px;
  margin-bottom: 20px;
}

.box {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1),
    0 32px 64px -48px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.box p {
  padding: 30px;
  font-size: 18px;
  color: #8a94a5;
}

.box h2 {
  text-decoration: none;
  color: #15284b;
  font-size: 17px;
  text-align: left;
}

.box span {
  color: #8a94a5;
  font-size: 16px;
}

/* start Count CSS */
.count span {
  font-size: 40px;
  color: black;
  letter-spacing: 0.2rem;
}

.count p {
  color: blueviolet;
  font-size: 50px;
}

.count .my-span {
  font-weight: 300;
  font-size: 30px;
  color: black;
}

@media screen and (max-width: 768px) {
  .count span {
    font-size: 32px;
  }

  .count h1 {
    font-size: 32px;
  }

  .count .my-span {
    font-size: 20px;
  }

  .count {
    margin-bottom: 30px;
    text-align: center;
  }
}

/* Count CSS end */

.img-wrapper img {
  width: 100px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


.div-project h3 {
  font-size: 30px;
}

.proj-header p {
  color: rgb(85, 84, 84);
  font-weight: 350;
  font-size: 30px;
}

.bold {
  font-weight: 900;
}

.proj-btn {
  width: 140px;
  height: 50px;
  background-color: rgb(133, 150, 21);
  border-radius: 30px;
  border-color: rgb(85, 84, 84);
  color: white;
  transition: 0.5s;
  border: 2px solid;
}

.proj-btn:hover {
  background-color: #fff;
  border-color: rgb(85, 84, 84);
  font-weight: 600;
  color: rgb(85, 84, 84);
}

@media (min-width: 320px) and (max-width: 499px) {
  .pro-main {
    text-align: center;
    padding-top: 10px;
  }
}
@media (min-width: 768px) and (max-width: 499px) {
}

.img-fluid1 {
  width: 200px;
  height: 200px;
  margin-top: 60px;
}

.div-header h6 {
  color: gray;
}

.div-header p {
  font-size: 26px;
}

@media only screen and (min-width: 320px) and (max-width: 700px) {
  .marque img {
    width: 50px;
  }
}
