/* Portfolio.css */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
@media screen and (max-width: 480px) {
  .we-about {
    text-align: center;
  }
}

.pagination {
  gap: 25px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
}

.pagination__link {
  display: inline-block;
  margin: 0 5px;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination__link--active {
  background-color: #007bff;
  color: white;
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.pagination__link--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.cards-body{
  background-color: #1e4d5cc9;
}
